.box{
    border: solid;
    width: 100%;
    height: 500px;
    position: relative;
    padding: 2em;
    margin-bottom: 2em;

}

.select1{
    position: absolute;
    top: 20px;
    left: 600px;


}

.check2{
    position: absolute;
    top: 300px;
    left: 0px;
}

.check3{
    position: absolute;
    top: 300px;
    left: 25px;
}

.text1{
    margin-top: 1em;
    resize: none;
}

.text2{
  margin-top: 1em;
  resize: none;
  float: right;
}

.text3{
    resize: none;
    position: absolute;
    top: 400px;
    left: 20px;
}

.text4{
    resize: none;
    position: absolute;
    top: 400px;
    left: 600px;
}

.pai1{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 20px;
    width: 20%;
    height: 20%;
}

.filho{
    width: 100%;
    height: 100%;
}

.filho2{
    position: absolute;
    left: 365px;
    top: 20px;
    color: white;
    background-color: #567bf3;
    border: 2px solid #020203;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.seleExp{
    border: 2px solid #008CBA;
    background-color: rgb(248, 248, 248);
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    cursor: pointer;
}

.desseExp{
    border: 2px solid #008CBA;
    background-color: rgb(248, 248, 248);
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    cursor: pointer;
}

.traduSele{
    border: 2px solid #008CBA;
    background-color: rgb(248, 248, 248);
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    cursor: pointer;
}
