.imgpai{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 20%;
    height: 20%;
    border-radius: 5px;
    background-color: black;
    position: relative;
    left: 50%;
}

.img{
    position: absolute;
    left: 50%;
    top: -9px;
}

.img2{
position: absolute;
left: 50%;
top: -190px;
width: 280px;
height: 190px;
}

.img3{
    border: solid;
    width: 280px;
    height: 190px;
}

.area1{
    resize: none;
}
.area2{
    resize: none;
}
