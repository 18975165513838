.import{
    padding: 20px 10px;
    width: 200px;
    background-color: rgb(248, 248, 248);
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    border: 2px solid #008CBA;
    text-align: center;
    display: block;
    margin-top: 10px;
    cursor: pointer;
}

.fileImport{
    display: none;
}
