.imag{
    border: solid;
    width: 280px;
    height: 190px;
    position: absolute;
    top: 55%;
    left: 25%;
}

.imag1{
    border: solid;
    width: 280px;
    height: 190px;
    position: absolute;
    top: 82%;
    left: 25%;
}
